export const IS_LOADING = 'IS_LOADING';
export const TOGGLE_LEFT_DRAWER = 'TOGGLE_LEFT_DRAWER';
export const TOGGLE_RIGHT_DRAWER = 'TOGGLE_RIGHT_DRAWER';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const TOGGLE_SNACK = 'TOGGLE_SNACK';
export const OPEN_LEFT_DRAWER_ITEM = 'OPEN_LEFT_DRAWER_ITEM';
export const SIGN_UP_NEXT_STEP = 'SIGN_UP_NEXT_STEP';
export const SIGN_UP_PREVIOUS_STEP = 'SIGN_UP_PREVIOUS_STEP';
export const FULFILLED_USER = 'FULFILLED_USER';
export const ADD_HISTORICAL_EVENT = 'ADD_HISTORICAL_EVENT';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const VIEW_EMAIL = 'VIEW_EMAIL';
export const ASYNC_INIT = 'ASYNC_INIT';
export const CNTRL_IS_CLICKED = 'CNTRL_IS_CLICKED';
