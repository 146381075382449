import { Ability, AbilityBuilder } from '@casl/ability';
import async from 'async';

Ability.addAlias('update', 'patch');
Ability.addAlias('read', ['get', 'find']);
Ability.addAlias('delete', 'remove');

/**
 * helper function to fetch and map all
 * abilities to usable shape
 */
async function mapRulesToAbilities(entityRules: any[]): Promise<any> {
  const userAbilities = {
    create: [],
    read: [],
    update: [],
    delete: [],
    manage: [],
    createOwn: [],
    readOwn: [],
    updateOwn: [],
    deleteOwn: [],
    manageOwn: [],
  };
  const abilities = entityRules.reduce((a, c) => {
    a.push(...(c.abilities || []));
    return a;
  }, []);

  // populate user abilities
  if (Array.isArray(abilities) && abilities[0]) {
    await new Promise((resolve, reject) => {
      async.eachOfLimit(
        abilities,
        1,
        async.asyncify(async ability => {
          if (Array.isArray(ability?.services) && ability.services[0]) {
            await new Promise((res, rej) => {
              async.eachOfLimit(
                ability.can,
                1,
                (method: string, idx, cb) => {
                  // "accessGroup_" prefix just to make sure it won't conflict with any service name
                  // we add accessGroup.name, so on client side we can use group of service to
                  // determine customer access to the group, example, page dashboard, can contain
                  // several service calls required, and we don't want to check each one
                  // separately on the client side
                  userAbilities[method].push(`accessGroup_${ability.name}`);
                  userAbilities[method].push(...ability.services);
                  cb();
                },
                err => (err ? rej(err) : res(undefined))
              );
            });
          }
        }),
        err => (err ? reject(err) : resolve(undefined))
      );
    });
  }

  return userAbilities;
}

/**
 * defining user abilities based on his rules
 */
export default async function actions(entityRules: any[] = [], tid: string, uid: string): Promise<any> {
  const { rules, can } = AbilityBuilder.extract();

  // defining abilities based on users rules
  if (Array.isArray(entityRules) && entityRules[0]) {
    const ability = await mapRulesToAbilities(entityRules);

    // within tenant scope
    if (ability.create[0]) {
      can('create', ability.create, { tid });
    }
    if (ability.read[0]) {
      can('read', ability.read, { tid });
    }
    if (ability.update[0]) {
      can('update', ability.update, { tid });
    }
    if (ability.delete[0]) {
      can('delete', ability.delete, { tid });
    }
    if (ability.manage[0]) {
      can('manage', ability.manage, { tid });
    }

    // only own
    if (ability.createOwn[0]) {
      can('create', ability.createOwn, { tid, uid });
    }
    if (ability.readOwn[0]) {
      can('read', ability.readOwn, { tid, uid });
    }
    if (ability.updateOwn[0]) {
      can('update', ability.updateOwn, { tid, uid });
    }
    if (ability.deleteOwn[0]) {
      can('delete', ability.deleteOwn, { tid, uid });
    }
    if (ability.manageOwn[0]) {
      can('manage', ability.manageOwn, { tid, uid });
    }
  }

  return rules;
}
