import {
  ADD_HISTORICAL_EVENT,
  ADD_NOTIFICATION,
  ASYNC_INIT,
  CNTRL_IS_CLICKED,
  FULFILLED_USER,
  IS_LOADING,
  OPEN_LEFT_DRAWER_ITEM,
  REMOVE_NOTIFICATION,
  TOGGLE_LEFT_DRAWER,
  TOGGLE_POPUP,
  TOGGLE_RIGHT_DRAWER,
  TOGGLE_SNACK,
} from '../actions/types';
import initialState from '../initial-state';

interface State {
  [x: string]: any;
}

interface Action {
  type: string;

  [x: string]: any;
}

export function state(s: State = initialState.state, action: Action): any {
  if (action.type.slice(-7) === 'PENDING' || action.type === '@@redux-form/START_SUBMIT') {
    return { ...s, loading: true };
  }
  if (
    action.type.slice(-9) === 'FULFILLED' ||
    action.type.slice(-8) === 'REJECTED' ||
    action.type === '@@redux-form/STOP_SUBMIT'
  ) {
    return { ...s, loading: false };
  }

  switch (action.type) {
    case ASYNC_INIT:
      return { ...s, ...action.payload };
    case IS_LOADING:
      return { ...s, loading: action.payload };
    case TOGGLE_LEFT_DRAWER:
      return { ...s, leftDrawer: typeof action.payload === 'boolean' ? action.payload : !s.leftDrawer };
    case TOGGLE_RIGHT_DRAWER:
      return { ...s, rightDrawer: typeof action.payload === 'boolean' ? action.payload : !s.rightDrawer };
    case TOGGLE_POPUP:
      return { ...s, openPopup: action.payload.openPopup, anchorEl: action.payload.anchorEl };
    case TOGGLE_SNACK:
      return { ...s, snackMsg: action.payload };
    case CNTRL_IS_CLICKED:
      return { ...s, cntrlIsPressed: action.payload };
    case OPEN_LEFT_DRAWER_ITEM:
      return { ...s, leftDrawOpenItem: action.payload };
    case ADD_HISTORICAL_EVENT:
      const eventsHistoryCopy = s.eventsHistory.slice(0, 49);
      const callIdx = eventsHistoryCopy.findIndex(c => c.id === action.payload.id);

      if (!~callIdx) {
        eventsHistoryCopy.reverse().push(action.payload);
      } else {
        eventsHistoryCopy[callIdx] = action.payload;
      }

      return { ...s, eventsHistory: eventsHistoryCopy.reverse() };
    case ADD_NOTIFICATION:
      const ntf = s.notifications.reverse().slice(0, 49).reverse();
      if (!ntf.find(n => n.timestamp === action.payload.timestamp)) {
        ntf.push(action.payload);
      }
      return { ...s, notifications: ntf };
    case REMOVE_NOTIFICATION:
      const notifications = [...s.notifications];
      const idx = notifications.findIndex(n => n.timestamp === action.payload.timestamp);
      if (~idx) {
        notifications.splice(idx, 1);
      }
      return { ...s, notifications };
    case FULFILLED_USER:
      return { ...s, user: action.payload };
    default:
      return s;
  }
}
