import async from 'async';

import populateAbilities from './populate-abilities';
// import { Auth0Lock } from 'auth0-lock';
import {
  ADD_HISTORICAL_EVENT,
  ADD_NOTIFICATION,
  ASYNC_INIT,
  CNTRL_IS_CLICKED,
  IS_LOADING,
  OPEN_LEFT_DRAWER_ITEM,
  REMOVE_NOTIFICATION,
  TOGGLE_LEFT_DRAWER,
  TOGGLE_POPUP,
  TOGGLE_RIGHT_DRAWER,
  TOGGLE_SNACK,
  VIEW_EMAIL,
} from './types';
import api from '../../lib/api-client';
import logger from '../../lib/logger';
import asyncInitResolvers from '../async-initial-state';

const loadingQueue: any = {};

const heapInitUser = user => {
  // @ts-ignore
  if (window?.heap) {
    // @ts-ignore
    window.heap.identify(`${user?.tid?.key}:${user.email}`);
    // @ts-ignore
    window.heap.addUserProperties({
      name: user.name || user.email?.split('@')[0],
      email: user.email,
      tid: user?.tid?._id,
      tenantKey: user?.tid?.key,
    });
  }
};

export const refreshBadges =
  (payload: string) =>
  async (dispatch: any): Promise<any> => {
    const state = { badges: {} };
    state.badges = await asyncInitResolvers.badges(payload);
    return dispatch({ type: ASYNC_INIT, payload: state });
  };
export const cntrlIsPressed =
  (payload: boolean) =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: CNTRL_IS_CLICKED, payload });
  };
export const asyncInitState =
  (payload: string, methods?: string[]) =>
  async (dispatch: any): Promise<any> => {
    const initState = await new Promise((resolve, reject) => {
      const state = { asyncInit: true };

      if (Array.isArray(methods) && methods.length) {
        async.mapLimit(
          Object.keys(asyncInitResolvers),
          3,
          async.asyncify(async key => {
            if (methods?.includes(key)) {
              state[key] = await asyncInitResolvers[key](payload);
            }
            return state;
          }),
          err => {
            if (err) {
              reject(err);
            } else {
              resolve(state);
            }
          }
        );
      }
    });
    return dispatch({ type: ASYNC_INIT, payload: initState });
  };
export const isLoading =
  (payload: boolean, reqId?: string) =>
  async (dispatch: any): Promise<any> => {
    if (reqId && typeof loadingQueue[reqId] === 'boolean' && payload) {
      loadingQueue[reqId] = true;
    }
    if (reqId && typeof loadingQueue[reqId] === 'boolean' && !payload) {
      delete loadingQueue[reqId];
    }

    const loading = Boolean(payload || Object.keys(loadingQueue)?.length);

    // mark as not loading if no requests are loading and payload is false
    return dispatch({ type: IS_LOADING, payload: loading });
  };
export const refreshUser =
  (payload: string) =>
  async (dispatch: any): Promise<any> => {
    // do not use .get
    return api.rest
      .service('v1/objects/users')
      .get(payload, {
        query: {
          $populate: [
            'preferences',
            {
              path: 'warehouseLocation',
              select: ['_id', 'warehouse'],
            },
            {
              path: 'rules',
              select: ['name', 'abilities'],
            },
            {
              path: 'tid',
              populate: [{ path: 'subscription.plan' }],
            },
          ],
        },
      })
      .then(async user => {
        if (!user?._id) {
          throw new Error('User not found');
        }

        user.warehouse = user?.warehouseLocation?.warehouse;
        user.warehouseLocation = user?.warehouseLocation?._id || user?.warehouseLocation;
        delete user.location;

        const abilities = await populateAbilities(user.rules, user.tid, user._id);

        await dispatch({
          type: 'FULFILLED_USER',
          payload: {
            ...user,
            abilities,
            rules: user.rules.map(r => r.name),
            tid: user.tid._id,
            tenant: user.tid,
          },
        });

        heapInitUser(user);
        return { ...user, abilities, rules: user.rules.map(r => r.name) };
      })
      .catch(err => {
        if (err?.message?.includes('jwt expired')) {
          throw new Error('Your session has expired, please login again.');
        }
        if (err?.message?.includes('Invalid login')) {
          throw new Error('Your entered incorrect login or password.');
        }
        throw err;
      });
  };
export const login =
  (payload: any) =>
  async (dispatch: any): Promise<any> => {
    return api.rest
      .authenticate(payload)
      .then(async res => {
        const [user] = await Promise.all([
          api.rest.service('v1/objects/users').get(res.user._id, {
            query: {
              $populate: [
                'preferences',
                {
                  path: 'warehouseLocation',
                  select: ['_id', 'warehouse'],
                },
                {
                  path: 'rules',
                  select: ['name', 'abilities'],
                },
                {
                  path: 'tid',
                  populate: [{ path: 'subscription.plan' }],
                },
              ],
            },
          }),
          api.socket
            .authenticate({
              strategy: 'jwt',
              accessToken: res.accessToken,
            })
            .catch(e => logger.error(`socket auth error: ${e?.message}`)),
        ]);

        if (!user?._id) {
          throw new Error('User not found');
        }

        user.warehouse = user?.warehouseLocation?.warehouse;
        user.warehouseLocation = user?.warehouseLocation?._id || user?.warehouseLocation;
        delete user.location;
        const abilities = await populateAbilities(user.rules, user.tid, user._id);

        await dispatch({
          type: 'FULFILLED_USER',
          payload: {
            ...user,
            abilities,
            rules: user.rules.map(r => r.name),
            tid: user.tid._id,
            tenant: user.tid,
          },
        });

        logger.rollbar.configure({
          payload: {
            person: {
              _id: user._id,
              id: user.id,
              tid: user.tid?._id || user.tid,
              key: user.tid?.key,
              email: user.email,
            },
          },
        });

        heapInitUser(user);
        return { ...user, abilities, rules: user.rules.map(r => r.name) };
      })
      .catch(err => {
        if (err?.message?.includes('jwt expired')) {
          throw new Error('Your session has expired, please login again.');
        }
        if (err?.message?.includes('Invalid login')) {
          throw new Error('Your entered incorrect login or password.');
        }
        throw err;
      });
  };
export const logout =
  (payload: any) =>
  async (dispatch: any): Promise<any> => {
    // const lock = new Auth0Lock(client_id, domain);

    return Promise.all([
      // @ts-ignore
      api.rest.logout(payload),
      // @ts-ignore
      api.socket.logout(payload).catch(logger.error),
      // lock.logout(),
    ]).then(res => {
      dispatch({ type: 'FULFILLED_USER', payload: null });
      return res;
    });
  };
export const addHistoricalEvent =
  (payload: any) =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: ADD_HISTORICAL_EVENT, payload });
  };
export const addNotification =
  (payload: any) =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: ADD_NOTIFICATION, payload });
  };
export const removeNotification =
  (payload: any) =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: REMOVE_NOTIFICATION, payload });
  };
export const toggleLeftDrawer =
  (payload?: boolean) =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: TOGGLE_LEFT_DRAWER, payload });
  };
export const toggleRightDrawer =
  (payload?: boolean) =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: TOGGLE_RIGHT_DRAWER, payload });
  };
export const togglePopup =
  (payload: any = {}) =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: TOGGLE_POPUP, payload });
  };
export const toggleSnack =
  (payload: any = '') =>
  async (dispatch: any): Promise<any> => {
    if (typeof payload === 'object' && !payload.infinity) {
      setTimeout(() => dispatch({ type: TOGGLE_SNACK, payload: '' }), payload.timeout);
    } else if (payload && typeof payload === 'string') {
      setTimeout(() => dispatch({ type: TOGGLE_SNACK, payload: '' }), 5000);
    } else {
      setTimeout(() => dispatch({ type: TOGGLE_SNACK, payload: '' }), 60000);
    }
    return dispatch({
      type: TOGGLE_SNACK,
      payload: typeof payload === 'object' ? payload.message : payload,
    });
  };
export const viewEmail =
  (payload = '') =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: VIEW_EMAIL, payload });
  };
export const openLeftDrawerItem =
  (payload = '') =>
  async (dispatch: any): Promise<any> => {
    return dispatch({ type: OPEN_LEFT_DRAWER_ITEM, payload });
  };
