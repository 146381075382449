import { bindActionCreators, Dispatch } from 'redux';

import {
  addHistoricalEvent,
  addNotification,
  asyncInitState,
  cntrlIsPressed,
  isLoading,
  login,
  logout,
  refreshBadges,
  refreshUser,
  removeNotification,
  toggleLeftDrawer,
  toggleSnack,
  viewEmail,
} from './actions';
import { services } from './feathers-redux';

/**
 * initializing default methods that will be accessible
 * for all components from [props]
 */
export default function defaultDispatchers(dispatch: Dispatch): any {
  const dispatchers = {
    dispatch,
    cntrlIsPressed: bindActionCreators(cntrlIsPressed, dispatch),
    toggleLeftDrawer: bindActionCreators(toggleLeftDrawer, dispatch),
    asyncInitState: bindActionCreators(asyncInitState, dispatch),
    refreshBadges: bindActionCreators(refreshBadges, dispatch),
    addHistoricalEvent: bindActionCreators(addHistoricalEvent, dispatch),
    addNotification: bindActionCreators(addNotification, dispatch),
    removeNotification: bindActionCreators(removeNotification, dispatch),
    toggleSnack: bindActionCreators(toggleSnack, dispatch),
    viewEmail: bindActionCreators(viewEmail, dispatch),
    login: bindActionCreators(login, dispatch),
    refreshUser: bindActionCreators(refreshUser, dispatch),
    logout: bindActionCreators(logout, dispatch),
    isLoading: bindActionCreators(isLoading, dispatch),
    services: {},
  };

  Object.keys(services).forEach(key => {
    dispatchers.services[key] = {
      get: bindActionCreators(services[key].get, dispatch),
      find: bindActionCreators(services[key].find, dispatch),
      create: bindActionCreators(services[key].create, dispatch),
      patch: bindActionCreators(services[key].patch, dispatch),
      update: bindActionCreators(services[key].update, dispatch),
      remove: bindActionCreators(services[key].remove, dispatch),
      onCreated: bindActionCreators(services[key].onCreated, dispatch),
      onPatched: bindActionCreators(services[key].onPatched, dispatch),
      onUpdated: bindActionCreators(services[key].onUpdated, dispatch),
      onRemoved: bindActionCreators(services[key].onRemoved, dispatch),
    };
  });

  return dispatchers;
}
