import reduxifyServices from 'feathers-redux';

import mappedServicesNames from './mapped-services-names';
import api from '../lib/api-client';

const servicesReducers = {};

/**
 * exporting main object to reuse as an action
 */
export const services = reduxifyServices(api.rest, mappedServicesNames, { idField: '_id' });

/**
 * separating and exporting reducers for more convenient use
 */
Object.keys(services).forEach(key => (servicesReducers[key] = services[key].reducer));

export default servicesReducers;
