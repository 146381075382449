import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import servicesReducers from './feathers-redux';
import initialState from './initial-state';
import * as reducers from './reducers';

const isDev = process.env.BUILD_ENV !== 'production';
const composeEnhancers = composeWithDevTools({
  autoPause: true,
  latency: 1000,
  maxAge: 30,
});

export default function store(state = initialState) {
  return createStore(
    // @ts-ignore
    combineReducers({
      ...reducers,
      ...servicesReducers,
      form: formReducer,
    }),
    // @ts-ignore
    {
      ...initialState,
      ...state,
    },
    isDev
      ? composeEnhancers(applyMiddleware(thunk, promiseMiddleware()))
      : compose(applyMiddleware(thunk, promiseMiddleware()))
  );
}
