import { faUserLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme): any => ({
  flex: {
    display: 'flex',
    overflow: 'visible',
    width: '100%',
    height: '100vh',
  },
  flexColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    marginRight: theme.spacing(1),
    '&:last-child': { marginRight: 0 },
  },
  flexAlignCenter: { alignItems: 'center' },
}));

const Element = () => {
  const classes: any = useStyles();
  return (
    <div className={classNames(classes.flex, classes.flexAlignCenter)}>
      <div className={classNames(classes.flexColumn, classes.flexAlignCenter)}>
        <FontAwesomeIcon className={classes.rightIcon} size="10x" icon={faUserLock} />
        <br />
        <Typography variant="h5">You account is not active!</Typography>
      </div>
    </div>
  );
};

export default Element;
