// @flow
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'next/router';
import withRedux from 'next-redux-wrapper';

import globalStyles from './global-styles';
import { extendPropsForPages } from './hoc';
import defaultDispatchers from '../redux/default-dispatchers';
import initStore from '../redux/store';

/**
 * separate wrapper for pages you can't use the one
 * for regular components because page must be wrapped
 * with [withRedux]
 */
export default function connect({ styles = {}, dispatchers, state }: any = {}): any {
  const initProps = (redux: any): any => {
    if (typeof state === 'function') {
      return { redux: state(redux) };
    }
    return { redux };
  };
  
  const getDispatchers = (dispatch: any): any => {
    // @ts-ignore
    const methods = defaultDispatchers(dispatch);
    
    if (typeof dispatchers === 'function') {
      // @ts-ignore
      return { ...dispatchers(dispatch), ...defaultDispatchers(dispatch) };
    }
    
    return methods;
  };
  
  return function (component: any): any {
    // @ts-ignore
    return withRedux(
      initStore,
      initProps,
      getDispatchers
    )(
      withStyles(
        t => {
          const s = typeof styles === 'function' ? styles(t) : styles;
          
          return {
            ...globalStyles(t),
            ...s,
          };
          // @ts-ignore
        },
        { withTheme: true }
      )(withRouter(extendPropsForPages(component)))
    );
  };
}
