import { LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

const styles = (theme: any): any => ({
  root: {
    top: 0,
    position: 'fixed',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    zIndex: 100000,
  },
  progress: { height: 3 },
});

const loader = ({ classes }): any => (
  <div className={classes.root}>
    {/*// @ts-ignore*/}
    <LinearProgress className={classes.progress} color="secondary" mode="query" />
  </div>
);

export default withStyles(styles, { withTheme: true })(loader) as any;
